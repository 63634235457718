import React from 'react'
import LogRocket from 'logrocket'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Toaster } from 'react-hot-toast'
import MainRouter from './MainRouter'
import theme from './utils/theme'

const AppProviders = (): JSX.Element => {

  LogRocket.init('v8esin/michman-of')

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainRouter />
      <Toaster position='bottom-center' />
    </ThemeProvider>
  )
}

export default AppProviders