import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

type Props = {
  children: React.ReactNode
  className?: React.CSSProperties,
  style?: React.CSSProperties,
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    padding: spacing(1),
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    flex: 1,
  },
}), { name: 'Container' })

function Container(props: Props): JSX.Element {
  const classes = useStyles()

  const {
    className,
    style,
  } = props

  return (
    <div className={`${classes.root} ${className} rtl`} style={style}>
      {props.children}
    </div>
  )
}

export default Container