import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './App.css'
import Home from './views/Home/Home'

const MainRouter = (): JSX.Element => {

  return (
    <BrowserRouter>
      <div>
        {/* <NavBar /> */}
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="*"
              element={<Navigate to="/" replace />}
            />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  )
}

export default MainRouter