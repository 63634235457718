/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMediaQuery } from '@mui/material'

const HomeLogic = () => {
  const isMobile = useMediaQuery('(max-width: 550px)')

  const dialogStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: isMobile ? '100vw' : 360,
    height: isMobile ? '100vh' : 650,
    borderRadius: 20,
    backgroundColor: 'whitesmoke'
  }

  return {
    dialogStyle,
  }
}

export default HomeLogic