import React from 'react'
import { DialogWrapper, StepContext } from '@open-finance-ai/components'
import HomeLogic from './HomeLogic'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Button from '../../generic/Button'
import Container from '../../generic/Container/Container'
import logo from '../../assets/goldpay-logo.jpeg'


const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    placeContent: 'space-between',
  },
}), { name: 'Home' })

const Home = (): JSX.Element => {
  const { dialogStyle } = HomeLogic()
  const classes = useStyles()

  return (
    <DialogWrapper
      numberOfPhases={0}
      preventBackAtSteps={[6]}
      onClickClose={() => { }}
      style={dialogStyle}
      Image={() => <img src={logo} alt='logo' width={60} />}
      isRtl={true}
    >
      <StepContext.Consumer>
        {({ step }): JSX.Element | null => {
          return step === 1 ? (
            <Container>
              <form
                className={classes.form}
              >
                <div>
                  <Typography variant='h5' textAlign='center' padding={1}>
                    ברוכים הבאים למסע בקשת הלוואה
                  </Typography>
                  <Typography variant='h5' textAlign='center' padding={1}>
                    כדי להתחיל נא לחץ על המשך
                  </Typography>
                </div>
                <div>
                  <Button title='המשך' type='submit' />
                </div>
              </form>
            </Container>
          ) : null
        }}
      </StepContext.Consumer>
    </DialogWrapper>
  )
}

export default Home
