import React from 'react'
import { makeStyles } from '@mui/styles'
import { Alert, Snackbar, Theme } from '@mui/material'
import { Loader } from '@open-finance-ai/components'
import { ButtonUnstyled } from '@mui/base'
import theme from '../../utils/theme'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  getStartedContainer: {
    display: 'flex',
    flex: 1,
    placeContent: 'center',
    height: 60,
  },
  getStartedBtn: {
    display: 'flex',
    flex: 1,
    margin: '5px',
    fontWeight: 'bold',
    fontSize: 16,
    color: 'black',
    width: '100%',
    height: '50px',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    textTransform: 'uppercase',
    borderRadius: spacing(),
    fontFamily: theme.typography.fontFamily,
    borderWidth: 0,
    transitionDuration: '250ms',
    '&:hover': {
      boxShadow: '0px 0px 8px #2c2c2c',
    },
  },
}), { name: 'Button' })

interface Props {
  title: string
  onClick?: () => void
  errorMessage?: string
  isError?: boolean
  handleCloseError?: () => void
  style?: React.CSSProperties
  type?: string
  isLoading?: boolean
}

const Button = (props: Props): JSX.Element => {
  const classes = useStyles()

  const { title, onClick, errorMessage, isError, handleCloseError, style, type, isLoading } = props

  return (
    <div className={classes.getStartedContainer}>
      {isLoading ? <Loader /> : (
        <ButtonUnstyled
          className={classes.getStartedBtn}
          onClick={onClick}
          type={type as "button" | "reset" | "submit" | undefined}
          style={{ backgroundColor: '#FFD700', ...style }}
        >
          {title}
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={isError}
            onClose={handleCloseError}
            key={'bottom' + 'center'}
          >
            <Alert severity='error'>{errorMessage}</Alert>
          </Snackbar>
        </ButtonUnstyled>
      )}
    </div >
  )
}

export default Button